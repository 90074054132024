import PageHeader from "components/PageHeader";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IconContext } from "react-icons";
import { FaDownload } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

import { useRef, useState, useEffect } from "react";
import { getCounter, setCounter } from "apis";

export default function Download() {
  const [updated, setUpdated] = useState(false);
  const [counter, setCounterLocal] = useState("000000");
  useEffect(() => {
    if (!updated) {
      setUpdated(true);
      setCounter("download").then(() => {
        getCounter().then((data) => {
          setCounterLocal(data.data.cnt);
        });
      });
    }
  }, [updated]);
  function testCounter(name) {
    console.log(`name ==== ${name}`);
  }
  return (
    <div>
      <IconContext.Provider value={{ color: "#0084ff" }}>
        <PageHeader title="DOWNLOADS" />
        <Container
          className="pb-5"
          style={{ textAlign: "center", marginTop: "20px" }}
        >
          <span
            style={{
              fontWeight: "bold",

              marginBottom: "-10px",
            }}
          >
            Presse & Publikationen
          </span>
          <Row xs={1} md={2} className="mb-5">
            {[
              {
                title: "Broschuere",
                file: "Info-PVPort&Store_2022.pdf",
                onclick: () => setCounter("Info-PVPort&Store_2022.pdf"),
              },
              {
                title: "PV-Port Design",
                file: "PV-Port_March_19.pdf",
                onclick: () => setCounter("PV-Port_March_19.pdf"),
              },
            ].map((el) => (
              <Col>
                <a
                  href={`/${el.file}`}
                  target="blank"
                  style={{ textDecoration: "none", color: "black" }}
                  onClick={el.onclick}
                >
                  <div className="border p-5 mt-5 d-flex download-card">
                    <p className="mb-0 fw-bold">{el.title}</p>
                    <FaDownload style={{ marginLeft: "10px" }} />
                  </div>
                </a>
              </Col>
            ))}
          </Row>
          <span
            style={{
              fontWeight: "bold",
              marginTop: "10px",
              marginBottom: "-10px",
            }}
          >
            Installation
          </span>
          <Row xs={1} md={2} className="mb-5">
            {[
              {
                title: "PV-Port Mechanical Structure",
                file: "manual_pvport.pdf",
                onclick: () => setCounter("manual_pvport.pdf"),
              },
              {
                title: "OPERATION & MAINTENANCE FOR CONSUMERS",
                file: "O_M for PV Port  _ Store.pdf",
                onclick: () => setCounter("O_M for PV Port  _ Store.pdf"),
              },
              {
                title: "Step by step installation guide",
                file: "PV_Port_handbook_V1.1.pdf",
                onclick: () => setCounter("PV_Port_handbook_V1.1.pdf"),
              },
            ].map((el) => (
              <Col>
                <a
                  href={`/${el.file}`}
                  target="blank"
                  style={{ textDecoration: "none", color: "black" }}
                  onClick={el.onclick}
                >
                  <div className="border p-5 mt-5 d-flex download-card">
                    <p className="mb-0 fw-bold">{el.title}</p>
                    <FaDownload style={{ marginLeft: "10px" }} />
                  </div>
                </a>
              </Col>
            ))}
          </Row>
          <span
            style={{
              fontWeight: "bold",
              marginTop: "10px",
              marginBottom: "-10px",
            }}
          >
            Technische Untersuchungen
          </span>

          <Row xs={1} md={2} className="mb-5">
            {[
              {
                title: "Wind load analysis PV-Port",
                file: "PV-Port_3_.pdf",
                onclick: () => setCounter("PV-Port_3_.pdf"),
              },
              {
                title: "Solar PV Port Bench",
                file: "Solar PV Port Bench - Developing new Design 4.pdf",
                onclick: () =>
                  setCounter(
                    "Solar PV Port Bench - Developing new Design 4.pdf"
                  ),
              },
            ].map((el) => (
              <Col>
                <a
                  href={`/${el.file}`}
                  target="blank"
                  style={{ textDecoration: "none", color: "black" }}
                  onClick={el.onclick}
                >
                  <div className="border p-5 mt-5 d-flex download-card">
                    <p className="mb-0 fw-bold">{el.title}</p>
                    <FaDownload style={{ marginLeft: "10px" }} />
                  </div>
                </a>
              </Col>
            ))}
          </Row>
          <span
            style={{
              fontWeight: "bold",
              marginTop: "10px",
              marginBottom: "-10px",
            }}
          >
            Vertragsunterlagen
          </span>

          <Row xs={1} md={2} className="mb-5">
            {[
              {
                title: "VEREINBARUNG ZUR PV-PORT NETZ-FREISCHALTUNG",
                file: "Netzanschlussvereinbarung.pdf",
                onclick: () => setCounter("Netzanschlussvereinbarung.pdf"),
              },
            ].map((el) => (
              <Col>
                <a
                  href={`/${el.file}`}
                  target="blank"
                  style={{ textDecoration: "none", color: "black" }}
                  onClick={el.onclick}
                >
                  <div className="border p-5 mt-5 d-flex download-card">
                    <p className="mb-0 fw-bold">{el.title}</p>
                    <FaDownload style={{ marginLeft: "10px" }} />
                  </div>
                </a>
              </Col>
            ))}
          </Row>
        </Container>
      </IconContext.Provider>
    </div>
  );
}
