import ACTION_TYPES from "constants/actionsTypes";
import { combineReducers } from "redux";
import loginReducer from "redux/Login/reducer";

const appReducer = combineReducers({ loginReducer });

const rootReducer = (state, action) => {
    console.debug("Action here: ", action);

    if (action.type === ACTION_TYPES.LOGIN.LOGOUT) {
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

export default rootReducer;