import Button from "react-bootstrap/Button";
import ReactPlayer from "react-player";
import HOME_VIDEO from "assets/videos/home.mp4";
import HowItWorks from "components/HowItWorks";
import Benefits from "components/Benefits";
import { useRef, useState, useEffect } from "react";
import Newsletter from "components/Newsletter";
import Gallery from "components/Gallery";
import { Col, Container, Row, Image } from "react-bootstrap";
import { FaMapMarked, FaPhoneAlt, FaEnvelopeOpen } from "react-icons/fa";
import Divider from "components/Divider";
import { Link } from "react-router-dom";
import Features from "components/Features";
import OurCollections from "components/OurCollections";
import api from "axios";
import { getCounter, setCounter } from "apis";
import { isMobile, isTablet } from "react-device-detect";
export default function Home() {
  const [updated, setUpdated] = useState(false);
  const [counter, setCounterLocal] = useState("000000");
  useEffect(() => {
    if (!updated) {
      setUpdated(true);
      setCounter("home").then(() => {});
    }
  }, [updated]);
  function NewlineText(props) {
    const text = props.text;
    return text.split("\n").map((str) => <p>{str}</p>);
  }
  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();

  return (
    <div className="w-100" style={{ minHeight: "100vh" }}>
      <div
        className="d-flex flex-column justify-content-evenly pt-5"
        style={{
          backgroundImage: `url(${require("assets/bg/header-background.jpeg")})`,
        }}
      >
        <Button
          onClick={executeScroll}
          variant="banner"
          className="d-block mx-auto mt-4"
          style={{ padding: "0 4vw" }}
        >
          Bestellen
        </Button>
        <div className="d-block mx-auto my-5" style={{ width: "85%" }}>
          <ReactPlayer
            alt="PV Port Promo Video Indien"
            url={HOME_VIDEO}
            controls
            width="100%"
            height="100%"
            loop={false}
            config={{
              file: {
                attributes: {
                  autoPlay: true,
                  muted: true,
                  preload: "",
                  controlsList: "nodownload",
                },
              },
            }}
          />
        </div>
      </div>
      <span id="howitworks"></span>
      <HowItWorks />
      <Benefits />
      <div ref={myRef}></div>
      <OurCollections />

      <Gallery />

      <Container className="py-5">
        <Row sm={2}>
          <Col className="m-auto d-block" style={{ textAlign: "center" }}>
            {isMobile ? (
              <h4>Kontaktieren Sie uns</h4>
            ) : (
              <h3>Kontaktieren Sie uns</h3>
            )}

            <Divider />
            {[
              {
                icon: <FaMapMarked style={{ width: "100%", height: "100%" }} />,
                text: " LESSZWEI GmbH, Naundörfchen 32, DE - 04109 Leipzig",
              },
              {
                icon: <FaPhoneAlt style={{ width: "100%", height: "100%" }} />,
                text: "+49 (0) 341 - 247 31 313",
              },
              {
                icon: (
                  <FaEnvelopeOpen style={{ width: "100%", height: "100%" }} />
                ),
                text: "info@home-pv.com",
                link: "",
              },
              {
                icon: (
                  <FaEnvelopeOpen style={{ width: "100%", height: "100%" }} />
                ),
                text: "vertrieb@home-pv.com",
                link: "",
              },
            ].map((el, index) => (
              <div
                className="d-flex"
                style={{ margin: "2.5rem 0" }}
                key={`home-address-${index}`}
              >
                <div
                  style={{
                    minWidth: "50px",
                    height: "50px",
                    marginRight: "40px",
                  }}
                >
                  {el.icon}
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  {el.link ? (
                    <p>{NewlineText(el.text)}</p>
                  ) : (
                    <a href={el.link} style={{ textDecoration: "none" }}>
                      {el.text}
                    </a>
                  )}
                </div>
              </div>
            ))}
            <Link to="/contact">
              <Button
                className="w-100 my-4"
                style={{
                  height: "4.5rem",
                  fontSize: "150%",
                  fontWeight: "bolder",
                }}
              >
                Kontaktieren Sie uns
              </Button>
            </Link>

            {[{ url: require("../../assets/lesszwei_logo.gif") }].map(
              (el, index) => (
                <Image
                  className="d-block"
                  style={{ width: "40%", height: "40%", marginLeft: "28%" }}
                  src={el.url}
                  alt={`LESSzwei GmbH Logo`}
                />
              )
            )}
          </Col>
        </Row>
        <Row></Row>
      </Container>
    </div>
  );
}
//lesszwei_logo.gif
/*
{ url: require("../../assets/home-slider/banner6.jpg") },
            ].map((el, index) => (
              <Carousel.Item key={`carousel-${index}`}>
                <Image
                  className="d-block w-100"
                  src={el.url}
                  alt={`${index} slide`}
                />
*/
