import { register } from "apis";
import { showNotification } from "helper";
import React, { useEffect, useState } from "react";
import Error from "components/Error";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import "react-notifications/lib/notifications.css";
export default function RegisterModal({
  show,
  onClose,
  product2,
  setShowLogin,
}) {
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [product, setProduct] = useState("");
  const [error, setError] = useState("");
  const [show2, setShow2] = useState(show);
  useEffect(() => {
    if (show !== undefined) {
      setShow2(show);
    }
  }, []);

  const handleNavigateToAnotherModal = (showFn) => {
    onClose();
    setTimeout(() => showFn(true), 250);
  };
  function handleClose() {
    setShow2(false);
  }
  console.log(show2);
  const handleRegister = (event) => {
    event.preventDefault();
    if (product !== "") {
      product2 = product;
    }
    console.log(name + "" + email + "" + phoneNumber + "" + product2);
    const registerResult = register(name, email, phoneNumber, product2)
      .then(() => {
        /* NotificationManager.success(
          "Vielen Dank dass Sie sich registriert haben!",
          "Registrierung"
        ); */
        setShow2(false);
        setEmail("");
        setPhoneNumber("");
        setName("");
        setProduct("");
        onClose();
      })
      .catch((error) => {
        NotificationManager.error(
          "Leider ist ein Fehler aufgetreten, bitte versuchen Sie es nocheinmal nachdem Sie Ihre Angaben ueberprueft haben!",
          "Registrierung"
        );
      });
  };

  return (
    <Modal show={show} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Register</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <NotificationContainer />
        <Form onSubmit={handleRegister}>
          <Row className="mb-3" xs={1} sm={2}>
            <Col>
              <Form.Group className="mb-1">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  required
                  placeholder="Geben Sie Ihren Namen ein"
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3" xs={1} sm={2}>
            <Col>
              <Form.Group className="mb-1">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  placeholder="Geben Sie Ihre Email ein"
                  type="text"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-1">
                <Form.Label>Telefon</Form.Label>
                <Form.Control
                  placeholder="Geben Sie Ihre Telefonnummer ein"
                  type="text"
                  value={phoneNumber}
                  onChange={(event) => setPhoneNumber(event.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3" xs={1} sm={2}>
            <Col>
              <Form.Group className="mb-1">
                <Form.Label>Produkt</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  defaultValue={product2}
                  onChange={(event) => setProduct(event.target.value)}
                >
                  <option value="Alle">Alle</option>
                  <option value="pv-lite">PV Port Lite</option>
                  <option value="pv-premium">PV Port Premium</option>
                  <option value="pv-lux">PV Port LUX</option>
                  <option value="city-direct">CITY Port Direct</option>
                  <option value="city-2">CITY Port 2</option>
                  <option value="city-5">CITY Port 5</option>
                  <option value="city-hybrid">CITY Port Hybrid</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Error error={error} style={{ textAlign: "center" }} />
          <Button
            variant="primary"
            type="submit"
            className="w-100 py-2 mb-4 mt-2"
          >
            Registrieren
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
