import api from "./axios";
import store from "./store";
let axiosConfig = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
};
export const register = (name, email, phoneNumber, product) =>
  api.post(
    "/newsletter",
    {
      name: name,
      email: email,
      mobile: phoneNumber,
      product: product,
      date: Date.now,
    },
    axiosConfig
  );

export const getCounter = () => api.get("/newsletter/counter", {}, axiosConfig);

export const setCounter = (name) =>
  api.post(
    "/newsletter/counter",
    {
      name: name,
    },
    axiosConfig
  );
export const submitContactUs = (payload) => api.post("/contact/", payload);
