import Divider from "components/Divider";
import React from "react";
import { useRef, useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import RetroHitCounter from "react-retro-hit-counter";
import "./styles.scss";
import { getCounter } from "apis";
export default function Footer() {
  const [counter, setCounter] = useState("000000");
  const footerLinks = [
    { title: "AGB", link: "agb" },
    { title: "Impressum", link: "impressum" },
  ];
  let counterNumbers = "001011";
  getCounter().then((data) => {
    console.log(data);
    setCounter(data.data.cnt);
  });

  return (
    <Container>
      <Row>
        <Divider variant="2" />

        <h6>
          <div class="hit-counter" style={{ textAlign: "center" }}>
            <span> Visitors:</span>
            {`${("000000" + counter).slice(-6)}`.split("").map((el, index) => {
              return <span>{el}</span>;
            })}
          </div>
        </h6>
        <Col className="d-flex justify-content-center">
          <ul
            className={isMobile ? "d-block" : "d-flex justify-content-center"}
          >
            {footerLinks.map((el, index) => (
              <li
                key={`footer-link-${index}`}
                className="mx-4"
                style={{
                  color: "#99ceff",
                  //   listStyle: index === 0 ? "none" : "outside",
                }}
              >
                <a href={`/${el.link}`}>{el.title}</a>
              </li>
            ))}
          </ul>
        </Col>

        <p className="footer-copyright">
          All rights reserved with Lesszwei GmbH, Naundörfchen 32, D-04107
          Leipzig. The concept of PV Port & Store, and City Port are patent
          pending by Simply Solar GbR (www.simply-solar.de) and Lesszwei GmbH
          (www.home-pv.com)
        </p>
      </Row>
    </Container>
  );
}
/*
.hit-counter span {
    display: inline-block;
    padding: 2px 8px;
    border-radius: 5px;
    background: white;
    color: #1e465d;
    margin-right: 4px;
    font-size: 1.5em;
}
*/
