import PageHeader from "components/PageHeader";
import React from "react";
import { Carousel, Container, Image, Row } from "react-bootstrap";
import { useRef, useState, useEffect } from "react";
import { getCounter, setCounter } from "apis";

export default function About(props) {
  const [updated, setUpdated] = useState(false);
  const [counter, setCounterLocal] = useState("000000");
  useEffect(() => {
    if (!updated) {
      setUpdated(true);
      setCounter("about").then(() => {
        getCounter().then((data) => {
          setCounterLocal(data.data.cnt);
        });
      });
    }
  }, [updated]);
  return (
    <div>
      <PageHeader title="PV PORT & STORE" subTitle="" />
      <Container>
        <Row>
          <p>
            Das Produkt PV Port & Store (ein DIY "System") ist eine
            standardisierte Plug-n-Play, tragbare und einfach zu installierende
            bis zu 3-kWp-Solar-PV-Anlage mit elektrischem Speicher für
            Anwendungen im privaten Wohnen und gewerblichen Anwendungen.
          </p>
          <p>
            Das System ist auch ohne feste Verankerung im Boden windfest bis zu
            Windgeschwindigkeiten von 120 km/h. Ermöglicht wird dies durch die
            aerodynamische Formgebung: Nutzung des Sogeffektes in Richtung
            Boden, der durch die Luftumströmung um die V-Form generiert wird
            sowie durch Ballastierung zum Abfangen von Windlasten durch
            wassergefüllte Hohlkörper aus Kunststoff. Wasser kann leicht
            aufgefüllt oder abgelassen werden, was das Transportgewicht
            minimiert und einen einfachen Umzug bei kurzfristigen Installationen
            erlaubt.
          </p>
          <p>
            Das PV PORT Model wird über ein direkte Anbindung (Verdrahtung,
            Einspeisung in Steckdose, etc) mit dem Haushaltsnetz einer Wohnung
            oder Hauses verbunden und versorgt ‚lastabhängig‘ den Strombedarf -
            selbstregulierend.
          </p>
          <p>
            Ein CITY PORT Model ist im freien Raum aufstellbar und dient somit
            als Stromversorgung zum Laden von Verbrauchern (Handy, PC, E-Bikes,
            E-Scooters, Klimaanlagen, Ventilatoren, Bedeutung, ...). Dabei sind
            4 Steckdosen mit 220 Volt und 8 USB Ports einzeln schaltbar und
            somit kann die Solarstrom-Abgabe auch kommerziell genutzt / verkauft
            werden.
          </p>
        </Row>
        <Carousel variant="dark">
          {[
            { url: require("../../assets/home-slider/1.jpg") },
            { url: require("../../assets/home-slider/2.jpg") },
            { url: require("../../assets/home-slider/3.jpg") },
            { url: require("../../assets/home-slider/banner4.jpg") },
            { url: require("../../assets/home-slider/banner5.jpg") },
            { url: require("../../assets/home-slider/banner6.jpg") },
          ].map((el, index) => (
            <Carousel.Item key={`carousel-${index}`}>
              <Image
                className="d-block w-100"
                src={el.url}
                alt={`Galleriebild ${index}`}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </div>
  );
}
