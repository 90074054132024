import { getProducs } from "apis";
import Divider from "components/Divider";
import { STATIC_BASE_URL } from "config";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Image,
  Row,
  Tab,
  Tabs,
  Text,
} from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./styles.scss";
import RegisterModal from "components/RegisterModal";
import { getCounter, setCounter } from "apis";
import { isMobile, isTablet } from "react-device-detect";
export default function OurCollections(props) {
  const [showRegister, setShowRegister] = useState(false);
  const [loaded, setLoaded] = useState(false);

  function NewlineText(props) {
    const text = props.text;
    return text.split("\n").map((str) => <p>{str}</p>);
  }
  function setStuff(product) {
    setProduct(product);
    setShowRegister(true);
  }
  const [product, setProduct] = useState();
  // const [products, setProducts] = useState([]);
  const [previewProductIndex, setPreviewProductIndexIntern] = useState(0);
  function setPreviewProductIndex(index) {
    setCounter(products[index].short + "-counter");
    setPreviewProductIndexIntern(index);
  }
  const products = [
    {
      short: "pv-lite",
      name: "PV Port Lite",
      info: `- PV Port without battery backup and benches
     - Feeds only into a Grid\n
     - DIY with electrician`,
      price: "MSRP: 6.200 EURO",
      tech: `
     Technical Details\n
     - Enphase IQ 7 * Microinverter
     Envoy-S metered single phase incl Zigbee current sensor\n
     - 6 x 335 Watt Poly/Mono Solar Modules with 25 y warranty\n
     - DiY Steel / powder coated structure with Tools`,
      image: require("../../assets/products/pv-0.jpg"),
    },
    {
      short: "pv-premium",
      name: "PV Port Premium",
      info: `- The advanced model of PV Port utilizing Lithium Phosphate batteries with 2.4 kWh storage \n
      - Grid Interactive & DIY with electrician`,
      price: "MSRP:9.490 EURO",
      tech: `
     Technical Details\n
     - Hybrid Inverter 230 Volt (1500-4000 Wp)\n
     - Blue Solar MPPT 150/35 and ComPort device com\n
     - 6 x 335 Watt Poly/Mono Solar Modules with 25 y warranty storage with 20-80% charging - 2 kWH and 6000 cycles to 80%\n
     - 6 x Solar Benches with 4 Liter Water Capacity and pre-wired electrical drawers\n
     - DiY Steel / powder coated structure with Tools`,
      image: require("../../assets/products/pv-1.jpeg"),
    },
    {
      short: "pv-lux",
      name: "PV Port Lux",
      info: `- The advanced model of PV Port utilizing Lithium Phosphate batteries with 4.8 kWh storage\n
     - DIY with electrician`,
      price: "MSRP: 11.760 EURO",
      tech: `
     Technical Details\n
     - Hybrid Inverter 230 Volt (1500-4000 Wp)\n
- Blue Solar MPPT 150/35 and ComPort device com\n
- 6 x 335 Watt Poly/Mono Solar Modules with 25 y warranty storage with 20-80% charging - 4kWH and 6000 cycles to 80%\n
- 4 x Solar Benches with 250 Liter Water Capacity and pre-wired electrical drawers\n
     - DiY Steel / powder coated structure with Tools`,
      image: require("../../assets/products/pv-2.jpeg"),
    },
    {
      short: "city-direct",
      name: "City Port Direct",
      info: `- PV Port without battery backup and benches\n
      - Feeds only into a Grid\n
      - Self mounting/balasting required\n
     - DIY with electrician`,
      price: "MSRP: 5.490 EURO",
      tech: `
     Technical Details\n
     - Enphase IQ 7 * Microinverter Envoy-S metered single phase incl Zigbee current sensor\n
     - 6 x 500 Watt Bifacial or Glass/Glass Solar Modules\n
     - DiY Steel / powder coated structure with Tools`,
      image: require("../../assets/products/pv-0.jpg"),
    },
    {
      short: "city-2",
      name: "City Port 2",
      info: `-The slim model of PV Port utilizing Lithium Phosphate batteries with 2.4 kWh storage\n
     - DIY`,
      price: "MSRP: 7.580 EURO",
      tech: `
     Technical Details\n
     - Hybrid Inverter 230 Volt (1500-4000 Wp) \n
      - Blue Solar MPPT 150/35 and ComPort device com\n
      - 6 x 335 Watt Bifacial or Glass/Glass Solar Modules\n
      - LFP electrical storage with 20-80% charging - 2 kWH and 6000 cycles to  80%\n
      - 4 x Solar Benches with 250 Liter water capacity 4 x IPxx electrical outlet and 8x IPxx USB port\n
     - DiY Steel / powder coated structure with Tools`,
      image: require("../../assets/products/city-1.png"),
    },
    {
      short: "city-5",
      name: "City Port 5",
      info: `-The advanced model of CITY Port utilizing Lithium Phosphate batteries with 4.8 kWh storage\n
     - DIY`,
      price: "MSRP: 8.500 EURO",
      tech: `
     Technical Details\n
     - Hybrid Inverter 230 Volt (1500-4000 Wp) \n
      - Blue Solar MPPT 150/35 and ComPort device com\n
      - 6 x 335 Watt Bifacial or Glass/Glass Solar Modules\n
      - LFP electrical storage with 20-80% charging - 4.8 kWH and 6000 cycles to  80%\n
      - 4 x Solar Benches with 250 Liter water capacity 4 x IPxx electrical outlet and 8x IPxx USB port\n
     - DiY Steel / powder coated structure with Tools`,
      image: require("../../assets/products/city-2.png"),
    },
    {
      short: "city-hybrid",
      name: "Hybrid Port",
      info: `-- The combined model of PV and CITY Port with Lithium Phosphate batteries with 5+ kWh storage\n
      - Grid interactive & stand-alone\n
     - DIY`,
      price: "MSRP: 15.500 EURO",
      tech: `
     Technical Details\n
     - Hybrid Smart Inverter 48/3000/35-32 230 Volt (1700 - 5000 Wp)\n
- Blue Solar MPPT 150/35\n
- Energy Meter and Zigbee converter\n
- 6 x 500 Watt Bifacial or Glass/Glass Solar Modules\n
- LFP electrical storage with 20-80% charging - 5+ kWH and 6000 cycles to 80%\n
      - 4 x Solar Benches with 250 Liter water capacity 4 x IPxx electrical outlet and 8x IPxx USB port\n
     - DiY Steel / powder coated structure with Tools`,
      image: require("../../assets/products/city-2.png"),
    },
  ];
  /*
name: "PV Port Lite",
infoP `- PV Port without battery
backup and benches
- Feeds only into a Grid
- DIY with electrician`,
price: "MSRP: 6.200 EURO",
tech: `
Technical Details
- Enphase IQ 7 * Microinverter
Envoy-S metered single phase incl
Zigbee current sensor
- 6 x 335 Watt Poly/Mono Solar
Modules with 25 y warranty
- DiY Steel / powder coated
structure with Tools`,
  */
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 5000, min: 1500 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1500, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  console.log("Products here: ", products);

  return (
    <div className="collections-container py-5" name="collection">
      <Container>
        <Row>
          <Col style={{ textAlign: "center" }}>
            {isMobile ? <h4>Unser Sortiment</h4> : <h3>Unser Sortiment</h3>}

            <Divider />
          </Col>
        </Row>
        <Carousel responsive={responsive} showDots={true} pauseOnHover={true}>
          {products.map((el, index) => (
            <div className="pb-5">
              <Image
                src={`${STATIC_BASE_URL}${el.image}`}
                style={{ width: "85%" }}
                className="d-block m-auto"
                onClick={() => setPreviewProductIndex(index)}
                alt={el.name}
              />
              <p
                className="text-center fw-bold mt-3"
                style={{ minHeight: "1.75rem" }}
              >
                {el.name}
              </p>
              <Button
                className="d-block m-auto"
                style={{
                  fontSize: "80%",
                  padding: "0.25rem 2rem",
                  borderRadius: "1vw",
                }}
                onClick={() => setPreviewProductIndex(index)}
              >
                Details
              </Button>
            </div>
          ))}
        </Carousel>
        {products.length > 0 && (
          <Row className="mt-5">
            <Col>
              <Image
                src={`${STATIC_BASE_URL}${products[previewProductIndex].image}`}
                style={{ width: "100%" }}
                alt={`${STATIC_BASE_URL}${products[previewProductIndex].name}`}
              />
            </Col>
            <Col>
              <h1 className="text-uppercase fw-bold">
                {products[previewProductIndex].name}
              </h1>

              <Tabs
                defaultActiveKey="0"
                id="uncontrolled-tab-example"
                className="m-1"
              >
                <Tab eventKey="0" title="Übersicht">
                  <NewlineText text={products[previewProductIndex].info} />

                  <p> {products[previewProductIndex].price}</p>

                  <Button
                    onClick={() =>
                      setStuff(products[previewProductIndex].short)
                    }
                  >
                    Für dieses Produkt registrieren
                  </Button>
                </Tab>
                <Tab eventKey="1" title="Details">
                  <NewlineText text={products[previewProductIndex].tech} />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        )}
      </Container>
      <RegisterModal
        show={showRegister}
        onClose={() => setShowRegister(false)}
        product2={product}
      />
    </div>
  );
}
