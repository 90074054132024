import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { IconContext } from "react-icons";
import {
  FaPiggyBank,
  FaPaw,
  FaSortAmountDown,
  FaChartLine,
  FaCreditCard,
  FaLightbulb,
  FaSun,
} from "react-icons/fa";
import "./styles.scss";
import Divider from "components/Divider";

export default function Benefits() {
  return (
    <div id="benefits-container" name="benefits">
      <Container style={{ padding: "0 2vw" }}>
        <Row>
          <Col className="m-auto">
            <br />
            <br />
            <h3>Vorteile</h3>
            <Divider />
          </Col>
        </Row>
        <Row xs="1" sm="3">
          <IconContext.Provider value={{ color: "#0084ff", size: 50 }}>
            {[
              {
                icon: <FaPiggyBank />,
                title: "Kostensparend",
                text: "",
              },
              {
                icon: <FaPaw />,
                title: "Reduzierung des CO2 Fußabdruck",
                text: "",
              },
              {
                icon: <FaSortAmountDown />,
                title: "Geringe Wartungskosten",
                text: "",
              },
              {
                icon: <FaChartLine />,
                title: "25-30 Jahre Lebensdauer",
                text: "",
              },
              {
                icon: <FaCreditCard />,
                title: "Amortisierung nach 5-6 Jahren",
                text: "",
              },
            ].map((el, index) => (
              <Col className="m-auto d-flex" key={`${index}-${el.title}`}>
                <div className="mt-3">{el.icon}</div>
                <div style={{ paddingLeft: "2vw" }} className="my-3">
                  <h4>{el.title}</h4>
                  <p className="text-center">{el.text}</p>
                </div>
              </Col>
            ))}
          </IconContext.Provider>
        </Row>
      </Container>
    </div>
  );
}
