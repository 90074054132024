const ACTION_TYPES = {
    LOGIN: {
        LOGIN: "LOGIN",
        LOGIN_REQUEST: "LOGIN_REQUEST",
        LOGIN_SUCCESS: "LOGIN_SUCCESS",
        LOGIN_FAILURE: "LOGIN_FAILURE",
        LOGIN_OTP_REQUEST: "LOGIN_OTP_REQUEST",
        LOGIN_OTP_SUCCESS: "LOGIN_OTP_SUCCESS",
        LOGIN_OTP_FAILURE: "LOGIN_OTP_FAILURE",
        RESEND_LOGIN_OTP_REQUEST: "RESEND_LOGIN_OTP_REQUEST",
        RESEND_LOGIN_OTP_SUCCESS: "RESEND_LOGIN_OTP_SUCCESS",
        RESEND_LOGIN_OTP_FAILURE: "RESEND_LOGIN_OTP_FAILURE",
        VERIFY_OTP_REQUEST: "LOGIN_VERIFY_OTP_REQUEST",
        VERIFY_OTP_SUCCESS: "LOGIN_VERIFY_OTP_SUCCESS",
        VERIFY_OTP_FAILURE: "LOGIN_VERIFY_OTP_FAILURE",
        LOGIN_CLEAR: "LOGIN_CLEAR",
        LOGIN_PASSWORD_REQUEST: "LOGIN_PASSWORD_REQUEST",
        LOGIN_PASSWORD_SUCCESS: "LOGIN_PASSWORD_SUCCESS",
        LOGIN_PASSWORD_FAILURE: "LOGIN_PASSWORD_FAILURE",
        FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
        FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
        FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
        LOGOUT_REQUEST: "LOGOUT_REQUEST",
        LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
        LOGOUT_FAILURE: "LOGOUT_FAILURE",
        LOGOUT: "LOGOUT",
    },
    SIGNUP: {
        SIGNUP_REQUEST: "SIGNUP_REQUEST",
        SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
        SIGNUP_FAILURE: "SIGNUP_FAILURE",
        SIGNUP_CLEAR: "SIGNUP_CLEAR",
        RESEND_VERITIFCATION_REQUEST: "RESEND_VERITIFCATION_REQUEST",
        RESEND_VERITIFCATION_SUCCESS: "RESEND_VERITIFCATION_SUCCESS",
        RESEND_VERITIFCATION_FAILURE: "RESEND_VERITIFCATION_FAILURE",
        VERIFY_OTP_REQUEST: "VERIFY_OTP_REQUEST",
        VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
        VERIFY_OTP_FAILURE: "VERIFY_OTP_FAILURE",
    },
    SEARCH: {
        SEARCH_REQUEST: "SEARCH_REQUEST",
        SEARCH_SUCCESS: "SEARCH SUCCESS",
        SEARCH_FAILURE: "SEARCH_FAILURE",
        SEARCH_CLEAR: "SEARCH_CLEAR",
    },
    PROFILE: {
        UPDATE_PROFILE_REQUEST: "UPDATE_PROFILE_REQUEST",
        UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
        UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",
        UPDATE_PROFILE_CLEAR: "UPDATE_PROFILE_CLEAR",
        MANAGE_ADDRESS: {
            GET_ADDRESSES_REQUEST: "GET_ADDRESSES_REQUEST",
            GET_ADDRESSES_SUCCESS: "GET_ADDRESSES_SUCCESS",
            GET_ADDRESSES_FAILURE: "GET_ADDRESSES_FAILURE",
            DELETE_ADDRESS_REQUEST: "DELETE_ADDRESS_REQUEST",
            DELETE_ADDRESS_SUCCESS: "DELETE_ADDRESS_SUCCESS",
            DELETE_ADDRESS_FAILURE: "DELETE_ADDRESS_FAILURE",
            ADD_ADDRESS_REQUEST: "ADD_ADDRESS_REQUEST",
            ADD_ADDRESS_SUCCESS: "ADD_ADDRESS_SUCCESS",
            ADD_ADDRESS_FAILURE: "ADD_ADDRESS_FAILURE",
            UPDATE_ADDRESS_REQUEST: "UPDATE_ADDRESS_REQUEST",
            UPDATE_ADDRESS_SUCCESS: "UPDATE_ADDRESS_SUCCESS",
            UPDATE_ADDRESS_FAILURE: "UPDATE_ADDRESS_FAILURE",
        },
    },
};

export default ACTION_TYPES;