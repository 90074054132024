import Footer from "components/Footer";
import Header from "components/Header";
import { Provider } from "react-redux";
import CustomRouter from "router";
import store, { persistor, sagaMiddleware } from "./store";
import rootSaga from "saga";
import { PersistGate } from "redux-persist/integration/react";
import "./App.scss";
import Loading from "screens/Loading";
import { useRef, useState, useEffect } from "react";
import { getCounter, setCounter } from "apis";
sagaMiddleware.run(rootSaga);

export default function App() {
  const [updated, setUpdated] = useState(false);
  const [counter, setCounterLocal] = useState("000000");
  useEffect(() => {
    if (!updated) {
      setUpdated(true);
      getCounter().then((data) => {
        setCounterLocal(data.data.cnt);
      });
    }
  }, [updated]);
  function NewlineText(props) {
    const text = props.text;
    return text.split("\n").map((str) => <p>{str}</p>);
  }
  return (
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <Header />

        <CustomRouter />
        <Footer counter={counter.toString()} />
      </PersistGate>
    </Provider>
  );
}
