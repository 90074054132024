import PageHeader from "components/PageHeader";
import React from "react";
import { Accordion, Container } from "react-bootstrap";

export default function FAQs() {
  return (
    <div>
      <PageHeader title="FAQS" subTitle="Answer to the most common questions" />
      <Container className="py-5">
        <Accordion defaultActiveKey="-1">
          {[
            {
              header:
                "What are the factors that can affect solar energy generation?",
              content:
                "The strength of the solar energy (radiation) available depends on the time of year, the time of day, and the latitude of the generation point. The amount of energy generated can be further affected by the amount of dust and water vapour in the air, the amount of cloud cover and any shading on the solar panels and the quality of the solar modules.",
            },
            {
              header: "What happens at night or when it’s cloudy?",
              content:
                "A rooftop solar PV system can only produce electricity if there is sunlight. This means that it can produce electricity on cloudy days as well provided sunlight is available. However, at night time, when there is no sun light, your solar system will not generate any electricity.",
            },
            {
              header: "Is my property suitable for solar PV system?",
              content:
                "Nearly all buildings have a roof that faces a suitable direction. Ideally south facing roofs are the best but systems can also be fitted on an east or west facing roofs. If there are no suitable roofs available, a system can be fitted on a floor. The solar panels will stand like a frame in the open area available.",
            },
            {
              header:
                "Can I use solar PV system to power my establishment like residential, commercial or industrial?",
              content:
                "Solar PV system can be used to power your entire establishment’s electrical systems, including lights, fans, ACs, cooling systems, and appliances.",
            },
            {
              header: "Should I wait for technology to improve?",
              content: `People often ask whether there is a new breakthrough on the horizon so that they hold-down the installation for the most modern system. In reality, over the last 18 years in business it has been found that technology moves slowly and breakthroughs in the lab rarely translate to practical solutions for people's homes or businesses.

                That said, if you have heard about something new that sounds interesting to you please let us know - our technology experts are always interested in discussing the latest entrants to the market.`,
            },
          ].map((el, index) => (
            <Accordion.Item eventKey={`${index}`}>
              <Accordion.Header>{el.header}</Accordion.Header>
              <Accordion.Body>{el.content}</Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Container>
    </div>
  );
}
