import PageHeader from "components/PageHeader";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function PrivacyPolicy() {
  return (
    <div>
      <PageHeader title="Privacy Policy" />
      <Container className="pt-5">
        <Row>
          <Col>
            <p>
              Your privacy matters to Deutsche Gesellschaft für Internationale
              Zusammenarbeit (GIZ) (the “Company” or the “Owner”, “we” or “us”).
            </p>
            <p>
              This Privacy Policy explains how we collect, process, use, share
              and protect information about you. It also tells you how you can
              access and update your information and make certain choices about
              how your information is used.
            </p>
            <p>
              The Privacy Policy covers both “online” (e.g., web and mobile
              services, including any websites operated by us such as
              https://www.home-pv.com/ or https://www.home-pv.ahasolar.in,
              Mobile / web Application, however accessed and/or used, whether
              via personal computers, mobile devices or otherwise) and “offline”
              (e.g., collection of data through mailings, telephone, or in
              person) activities owned, operated, provided, or made available by
              the Company. Our “online” and “offline” activities, in relation to
              facilitation of PV Port and Store marketplace services through a
              network of third party, are collectively referenced as the
              “Services”. This Privacy Policy also applies to your use of
              interactive features or downloads that: (i) we own or control;
              (ii) are available through the Services; or (iii) interact with
              the Services and post or incorporate this Privacy Policy.
            </p>
            <p>
              BY ACCEPTING THE CUSTOMER TERMS AND CONDITIONS, YOU AGREE TO THE
              TERMS OF THIS PRIVACY POLICY.{" "}
            </p>
            <p>
              Please review the following carefully so that you understand our
              privacy practices. If you do not agree to this Privacy Policy, do
              not accept the Customer Terms and Conditions or use our Services.
              This Privacy Policy is incorporated by reference into the Customer
              Terms and Conditions.
            </p>
            <p>
              If you have questions about this Privacy Policy, please contact us
              through email address provided on our website and/ or Mobile
              Application.
            </p>

            <b>1. Definitions</b>
            <p>
              Unless otherwise provided in this Privacy Policy, the terms
              capitalized in the Privacy Policy shall have the meaning as
              provided hereunder:
            </p>
            <ol type="a">
              <li>
                “Co-branded Services” shall have the meaning assigned to the
                term in paragraph 4(c) hereto.
              </li>
              <li>
                {" "}
                “Device” shall mean computer, mobile or other device used to
                access the Services.
              </li>
              <li>
                {" "}
                “Device Identifier” shall mean IP address or other unique
                identifier for the Device.
              </li>
              <li>
                {" "}
                “Mobile Application” shall mean application provided by us on
                the Device to access the Services.
              </li>
              <li>
                {" "}
                “Mobile Device Information” shall have the meaning assigned to
                the term in paragraph 2(d)(ii) hereto.
              </li>
              <li>
                {" "}
                “Promotion” shall mean any contest and other promotions offered
                by us.
              </li>
              <li>
                {" "}
                “Protected Information” shall mean such categories of
                information that could reasonably be used to identify you
                personally, including your name, e-mail address, and mobile
                number.
              </li>
              <li> “TPSP” shall mean a third party service provider.</li>
              <li>
                {" "}
                “Usage Information” shall have the meaning assigned to the term
                in paragraph 2(b)(i) hereto.
              </li>
            </ol>
            <p></p>

            <b>2. What Information Do We Collect?</b>
            <p></p>
            <ol type="A">
              <li>Information You Provide to Us</li>
              <p>
                We may ask you to provide us with certain Protected Information.
                We may collect this information through various means and in
                various places through the Services, including account
                registration forms, contact us forms, or when you otherwise
                interact with us. When you sign up to use the Services, you
                create a user profile. We shall ask you to provide only such
                Protected Information which is for lawful purpose connected with
                our Services and necessary to be collected by us for such
                purpose.The current data fields that might be requested for are:
              </p>
              <ul>
                <li>Email</li>
                <li>Password</li>
                <li>Name</li>
                <li>Address</li>
                <li>Mobile phone Number</li>
                <li>Zip Code</li>
                <li>Location of the device</li>
              </ul>

              <li>Information We Collect as You Access and Use Our Services</li>
              <ol type="i">
                <li>
                  In addition to any Protected Information or other information
                  that you choose to submit to us, we and our TPSP may use a
                  variety of technologies that automatically (or passively)
                  collect certain information whenever you visit or interact
                  with the Services (“Usage Information”). This Usage
                  Information may include the browser that you are using, the
                  URL that referred you to our Services, all of the areas within
                  our Services that you visit, and the time of day, among other
                  information. In addition, we collect your Device Identifier
                  for your Device. A Device Identifier is a number that is
                  automatically assigned to your Device used to access the
                  Services, and our computers identify your Device by its Device
                  Identifier.
                </li>
                <li>
                  In case of product booking/enquire via call Centre, the
                  company or its representation or any third party agency hired
                  by the company may record calls for quality and training
                  purposes.
                </li>
                <li>
                  In addition, location information is collected as your
                  installation through our Services, including, but not limited
                  to geographic areas.{" "}
                </li>
                <li>
                  iv. Usage Information may be collected using a cookie. If you
                  do not want information to be collected through the use of
                  cookies, your browser allows you to deny or accept the use of
                  cookies. Cookies can be disabled or controlled by setting a
                  preference within your web browser or on your Device.
                </li>
              </ol>
              <p>
                If you choose to disable cookies or flash cookies on your
                Device, some features of the Services may not function properly
                or may not be able to customize the delivery of information to
                you. The Company cannot control the use of cookies (or the
                resulting information) by third parties, and use of third party
                cookies is not covered by our Privacy Policy.
              </p>

              <li>Information Third Parties Provide About You</li>
              <p>
                We may, from time to time, supplement the information we collect
                about you through our website or Mobile Application or Services
                with outside records from third parties.
              </p>

              <li>Information Collected by Mobile Applications</li>
              <ol type="i">
                <li>
                  Our Services are primarily provided through the Mobile
                  Application. We may collect and use technical data and related
                  information, including but not limited to, technical
                  information about your device, system and application
                  software, and peripherals, that is gathered periodically to
                  facilitate the provision of software updates, product support
                  and other services to you (if any) related to such Mobile
                  Applications.
                </li>
                <li>
                  {" "}
                  When you use any of our Mobile Applications, the Mobile
                  Application may automatically collect and store some or all of
                  the following information from your mobile device (“Mobile
                  Device Information”), in addition to the Device Information,
                  including without limitation:
                </li>
                <li>
                  Your preferred language and country site (if applicable)
                </li>
                <li> Your mobile operating system</li>
                <li>The type of mobile internet browsers you are using</li>
                <li>
                  Your geolocation (i.e. co-ordinates of the
                  installation/booking)
                </li>
                <li>
                  {" "}
                  Information about how you interact with the Mobile Application
                  and any of our web sites to which the Mobile Application
                  links, such as how many times you use a specific part of the
                  Mobile Application over a given time period, the amount of
                  time you spend using the Mobile Application, how often you use
                  the Mobile Application, actions you take in the Mobile
                  Application and how you engage with the Mobile Application
                </li>
                <li>
                  {" "}
                  Information to allow us to personalize the services and
                  content available through the Mobile Application
                </li>
                <li>
                  Data from SMS/ text messages upon receiving Device permissions
                  for the purposes of (i) issuing and receiving one time
                  passwords and other device verification, and (ii)
                  automatically filling verification details during financial
                  transactions, either through us or a third-party service
                  provider, in accordance with applicable law. We do not share
                  or transfer SMS/ text message data to any third party other
                  than as provided under this Privacy Policy.
                </li>
              </ol>
            </ol>
            <p></p>
            <b>3. USE OF INFORMATION COLLECTED</b>
            <ol type="a">
              <li>
                Our primary goal in collecting your information is to provide
                you with an enhanced experience when using the Services. We use
                your information to closely monitor which features of the
                Services are used most, to determine which features we need to
                focus on improving, including usage patterns and geographic
                locations to determine where we should offer or focus services,
                features and/or resources.
              </li>
              <li>
                Based upon the Protected Information you provide us when
                registering for an account, we will send you a welcoming email
                to verify your username and password.
              </li>
              <li>
                We use the information collected from our Mobile
                Application/website so that we are able to serve you the correct
                app version depending on your device type, for troubleshooting
                and in some cases, marketing purposes.
              </li>
              <li>
                We use your Internet Protocol (IP) address to help diagnose
                problems with our computer server, and to administer our web
                site(s). Your IP address is used to help identify you, but
                contains no personal information about you.
              </li>
              <li>
                We will send you strictly service-related announcements on rare
                occasions when it is necessary to do so. For instance, if our
                Services are temporarily suspended for maintenance, we might
                send you an email. If you do not wish to receive them, you have
                the option to deactivate your account.
              </li>
              <li>
                We may use the information obtained from you to prevent,
                discover and investigate violations of this Privacy Policy or
                any applicable terms of service or terms of use for the Mobile
                Application, and to investigate fraud, chargeback or other
                matters.
              </li>
              <li>
                We provide some of your Protected Information (such as your
                name, installation address, contact number) to the installer/
                service provider who accepts your request for
                transportation/installation so that the transportation service
                or installation service may be provided. The companies for which
                transporters / installers work (that are providing the
                transportation service) are also able to access your Protected
                Information, including your geo-location data.
              </li>
              <li>
                In addition, we may use your Protected Information or Usage
                Information that we collect about you: (1) to provide you with
                information or services or process transactions that you have
                requested or agreed to receive including to send you electronic
                newsletters, or to provide you with special offers or
                promotional materials on behalf of us or third parties; (2) to
                enable you to participate in a variety of the Services’ features
                such as online or mobile entry sweepstakes, contests or other
                promotions; (3) to contact you with regard to your use of the
                Services and, in our discretion, changes to the Services and/or
                the Services’ policies; (4) for internal business purposes; (5)
                for inclusion in our data analytics; and (6) for purposes
                disclosed at the time you provide your information or as
                otherwise set forth in this Privacy Policy.
              </li>
              <li>
                We may use the information collected from you for targeted
                advertising. This involves using information collected on an
                individual's web or mobile browsing behavior such as the pages
                they have visited or the searches they have made. This
                information is then used to select which advertisements should
                be displayed to a particular individual on websites other than
                our web site(s). The information collected is only linked to an
                anonymous cookie ID (alphanumeric number); it does not include
                any information that could be linked back to a particular
                person, such as their name, address or credit card number.
              </li>
            </ol>

            <b>4. How and When Do We Disclose Information To Third Parties</b>
            <p>
              We do not sell, share, rent or trade the information we have
              collected about you, other than as disclosed within this Privacy
              Policy or at the time you provide your information. Following are
              the situations when information may be shared:
            </p>
            <p></p>
            <p>(a) WHEN YOU AGREE TO RECEIVE INFORMATION FROM THIRD PARTIES.</p>
            <p>
              You may be presented with an opportunity to receive information
              and/or marketing offers directly You may be presented with an
              opportunity to receive information and/or marketing offers
              directly from third parties. If you do agree to have your
              Protected Information shared, your Protected Information will be
              disclosed to such third parties and all information you disclose
              will be subject to the privacy policy and practices of such third
              parties. We are not responsible for the privacy policies and
              practices of such third parties and, therefore, you should review
              the privacy policies and practices of such third parties prior to
              agreeing to receive such information from them. If you later
              decide that you no longer want to receive communication from a
              third party, you will need to contact that third party directly.
            </p>

            <p>(b) THIRD PARTIES PROVIDING SERVICES ON OUR BEHALF</p>
            <p>
              We use third party companies and individuals to facilitate our
              Services, provide or perform certain aspects of the Services on
              our behalf – such as transporters , installers , electricians and
              companies they work for to provide the Services, other third
              parties including TFS (Team Foundation Serve) to host the
              Services, design and/or operate the Services’ features, track the
              Services’ analytics, process payments, engage in anti-fraud and
              security measures, provide customer support, provide geo-location
              information to our technicians, engineers, logistics service
              provider enable us to send you special offers, host our job
              application form, perform technical services (e.g., without
              limitation, maintenance services, database management, web
              analytics and improvement of the Services‘ features), or perform
              other administrative services. These third parties will have
              access to user information, including Protected Information to
              only carry out the services they are performing for you or for us.
              Each of these third parties including TFS are required to ensure
              the same level of data protection as us and are obligated not to
              disclose or use Protected Information for any other purpose.
              Analytics TPSPs (Third Party Service Provider) may set and access
              their own cookies, web beacons and embedded scripts on your Device
              and they may otherwise collect or have access to information about
              you, including non-personally identifiable information. We use a
              third party hosting provider who hosts our support section of our
              website. Information collected within this section of our website
              by such third party is governed by our Privacy Policy.
            </p>

            <p>(c) CO-BRANDED SERVICES.</p>
            <p>
              Certain aspects of the Services may be provided to you in
              association with third parties (“Co- Branded Services”) such as
              sponsors and charities, and may require you to disclose Protected
              Information to them. Such Co-Branded Services will identify the
              third party. If you elect to register for products and/or services
              through the Co-Branded Services, you shall have deemed to consent
              to providing your information to both us and the third party.
              Further, if you sign-in to a Co-Branded Service with a username
              and password obtained through our Services, your Protected
              Information may be disclosed to the identified third parties for
              that Co-Branded Service and will be subject to their posted
              privacy policies.
            </p>

            <p>(d) CONTESTS AND PROMOTIONS</p>
            <p>
              We may offer Promotions through the Services that may require
              registration. By participating in a Promotion, you are agreeing to
              official rules that govern that Promotion, which may contain
              specific requirements of you, including, allowing the sponsor of
              the Promotion to use your name, voice and/or likeness in
              advertising or marketing associated with the Promotion. If you
              choose to enter a Promotion, you agree that your Protected
              Information may be disclosed to third parties or the public in
              connection with the administration of such Promotion, including,
              in connection with winner selection, prize fulfillment, and as
              required by law or permitted by the Promotion’s official rules,
              such as on a winners list.
            </p>

            <p>(e) ADMINISTRATIVE AND LEGAL REASONS</p>
            <p>
              We cooperate with Government and law enforcement officials and
              private parties to enforce and comply with the law. Thus, we may
              access, use, preserve, transfer and disclose your information
              (including Protected Information, IP address, Device Information
              or geo-location data), to Government or law enforcement officials
              or private parties as we reasonably determine is necessary and
              appropriate: (i) to satisfy any applicable law, regulation,
              subpoenas, Governmental requests or legal process; (ii) to protect
              and/or defend the Terms and Conditions for online and mobile
              Services or other policies applicable to any online and mobile
              Services, including investigation of potential violations thereof;
              (iii) to protect the safety, rights, property or security of the
              Company, our Services or any third party; (iv) to protect the
              safety of the public for any reason; (v) to detect, prevent or
              otherwise address fraud, security or technical issues; and /or
              (vi) to prevent or stop activity we may consider to be, or to pose
              a risk of being, an illegal, unethical, or legally actionable
              activity.
            </p>

            <p>(f) WHEN YOU SHARE INFORMATION</p>
            <p>
              Protected Information may be collected and shared with
              third-parties if there is content from the Web / Mobile
              Application that you specifically and knowingly upload to, share
              with or transmit to an email recipient, online community, website,
              or to the public, e.g. uploaded photos, posted reviews or
              comments, or information about you or your ride that you choose to
              share with others through features which may be provided on our
              Services. This uploaded, shared or transmitted content will also
              be subject to the privacy policy of the email, online community
              website, social media or other platform to which you upload, share
              or transmit the content.
            </p>

            <p>(g) BUSINESS TRANSFER</p>
            <p>
              We may share your information, including your Protected
              Information and Usage Information with our parent, subsidiaries
              and affiliates for internal reasons, including business and
              operational purposes. We also reserve the right to disclose and
              transfer all such information: (i) to a subsequent owner, co-owner
              or operator of the Services or applicable database; or (ii) in
              connection with a corporate merger, consolidation, restructuring,
              the sale of substantially all of our membership interests and/or
              assets or other corporate change, including, during the course of
              any due diligence process.
            </p>

            <p>(h) MARKET STUDY AND OTHER BENEFITS</p>
            <p>
              We may share your information, including your Protected
              Information and Usage Information with third parties for any
              purpose, including but not limited to undertaking market research/
              study, conduct data analysis, determine and customize product or
              service offerings, to improve the products or Services or to make
              any other benefits/products/ services available to you.
            </p>

            <b>5. Third Party Content and Links To Third Party Services</b>
            <p>
              The Services may contain content that is supplied by a third
              party, and those third parties may collect website usage
              information and your Device Identifier when web pages from any
              online or mobile Services are served to your browser. In addition,
              when you are using the Services, you may be directed to other
              sites or applications that are operated and controlled by third
              parties that we do not control. We are not responsible for the
              privacy practices employed by any of these third parties. For
              example, if you click on a banner advertisement, the click may
              take you away from one of our websites onto a different web site.
              These other web sites may send their own cookies to you,
              independently collect data or solicit Protected Information and
              may or may not have their own published privacy policies. We
              encourage you to note when you leave our Services and to read the
              privacy statements of all third party web sites or applications
              before submitting any Protected Information to third parties.
            </p>

            <b>6. Social Media Features and Widgets</b>
            <p>
              Our online and mobile Services may include social media features,
              such as the Facebook Like button, and widgets such as a “Share
              This” button, or interactive mini-programs that run on our online
              and mobile Services. These features may collect your IP address,
              photograph, which page you are visiting on our online or mobile
              Services, and may set a cookie to enable the feature to function
              properly. Social media features and widgets are either hosted by a
              third party or hosted directly on our online Services. Your
              interactions with these features and widgets are governed by the
              privacy policy of the company providing them.
            </p>

            <b>7. Change of Information and Cancellation Of Account</b>
            <p>
              (a) You are responsible for maintaining the accuracy of the
              information you submit to us, such as your contact information
              provided as part of account registration. If your Protected
              Information changes, or if you no longer desire our Services, you
              may correct, delete inaccuracies, or amend information by making
              the change on our member information page or by contacting us
              through email address mentioned on our website or Mobile
              Application. We will make good faith efforts to make requested
              changes in our then active databases as soon as reasonably
              practicable.
            </p>
            <p>
              (b) You may also cancel or modify your communications that you
              have elected to receive from the Services by following the
              instructions contained within an e-mail or by logging into your
              user account and changing your communication preferences.
            </p>
            <p>
              (c) If upon modifying or changing the information earlier provided
              to Us, we find it difficult to permit access to our Services to
              you due to insufficiency/ inaccuracy of the information, we may,
              in our sole discretion terminate your access to the Services by
              providing you a written notice to this effect on your registered
              email id.
            </p>
            <b>8. Security</b>
            <p>
              The Protected Information and Usage Information we collect is
              securely stored within our databases, and we use standard,
              industry-wide, commercially reasonable security practices such as
              encryption, firewalls and SSL (Secure Socket Layers) for
              protecting your information. However, as effective as encryption
              technology is, no security system is impenetrable. We cannot
              guarantee the security of our databases, nor can we guarantee that
              information you supply won't be intercepted while being
              transmitted to us over the Internet or wireless communication, and
              any information you transmit to the Company you do at your own
              risk. We recommend that you not disclose your password to anyone.
            </p>

            <b>9. Grievance Officer</b>
            <p>
              GiZ hereby appoints GiZ Support Manager as the grievance officer
              for the purposes of the rules drafted under the Information
              Technology Act, 2000, who may be contacted at PV Port &amp; Store
              web site. You may address any grievances you may have in respect
              of this privacy policy or usage of your Protected Information or
              other data to him.
            </p>

            <b>10. Changes to the Privacy Policy</b>
            <p>
              From time to time, we may update this Privacy Policy to reflect
              changes to our information practices. Any changes will be
              effective immediately upon the posting of the revised Privacy
              Policy. If we make any material changes, we will notify you by
              email (sent to the e-mail address specified in your account) or by
              means of a notice on the Services prior to the change becoming
              effective. We encourage you to periodically review this page for
              the latest information on our privacy practices.
            </p>

            <b>11. Waiver</b>
            <p>
              The failure of GIZ to exercise or enforce any rights or provisions
              of this Terms of Use will not constitute a waiver of such rights
              or provisions. Any waiver of any provision of this Terms of Use
              will be effective only if in writing and signed by GIZ.
            </p>

            <b>12. Disclaimer</b>
            <p>
              This website is hosted by AHA Solar Pvt. Ltd. and Ahasolar Pvt.
              Ltd. URL and Server. In case of any loss or damage caused due to
              any activity performed through website then GiZ, Ahasolar Pvt.
              Ltd. or any of its employee or promoter shall not be liable for
              it. It is completely the responsibility of the user.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
