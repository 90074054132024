import { register } from "apis";
import AppButton from "components/Button";
import Error from "components/Error";
import PageHeader from "components/PageHeader";
import { showNotification } from "helper";
import React, { useState, useEffect } from "react";

import { Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import { validateEmail } from "utils";
import "./styles.scss";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import "react-notifications/lib/notifications.css";

import { getCounter, setCounter } from "apis";

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [updated, setUpdated] = useState(false);
  const [counter, setCounterLocal] = useState("000000");
  useEffect(() => {
    if (!updated) {
      setUpdated(true);
      setCounter("contact").then(() => {
        getCounter().then((data) => {
          setCounterLocal(data.data.cnt);
        });
      });
    }
  }, [updated]);

  useEffect(() => {
    if (error) {
      setError("");
    }
  }, [name, email, mobile, message]);

  const validatePayload = () => {
    if (!validateEmail(email)) {
      return { error: "Please enter a valid email address" };
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationResult = validatePayload({ email });
    if (validationResult.error) {
      setError(validationResult.error);
    } else {
      setIsSubmitting(true);

      register(name, email, mobile, message)
        .then((result) => {
          console.log(result);
          if (result && (result.status === 201 || result.status === 200)) {
            NotificationManager.success(
              "Vielen Dank fuer Ihre Nachricht!",
              "Contact Form"
            );

            setName("");
            setEmail("");
            setMobile("");
            setMessage("");
            setError("");
          }
        })
        .catch((error) => console.log("Error: ", error))
        .finally(() => setIsSubmitting(false));
    }
  };

  return (
    <div>
      <NotificationContainer />
      <PageHeader title="Kontaktformular" />
      <Container>
        <Row xs="1" className="p-4">
          <Col>
            <Form onSubmit={handleSubmit}>
              {[
                {
                  value: name,
                  label: "Name",
                  onChange: setName,
                  type: "text",
                  req: "required",
                },
                {
                  value: email,
                  label: "Email",
                  onChange: setEmail,
                  text: "email",
                  req: "required",
                },
                {
                  value: mobile,
                  label: "Telefonnummer",
                  onChange: setMobile,
                  type: "text",
                },
                {
                  value: message,
                  label: "Nachricht",
                  onChange: setMessage,
                  type: "textarea",
                },
              ].map((el) => (
                <FloatingLabel
                  controlId="floatingInput"
                  label={el.label}
                  className="mb-3"
                >
                  {el.type === "textarea" ? (
                    <Form.Control
                      style={{ height: "15vw" }}
                      as={el.type}
                      placeholder={`Enter your ${el.label}`}
                      value={el.value}
                      onChange={(e) => el.onChange(e.target.value)}
                    />
                  ) : (
                    <Form.Control
                      type={el.type}
                      placeholder={`Enter your ${el.label}`}
                      value={el.value}
                      onChange={(e) => el.onChange(e.target.value)}
                      required
                    />
                  )}
                </FloatingLabel>
              ))}
              <Error error={error} className="text-center" />
              <AppButton
                className="d-block mx-auto px-5 fw-bold"
                type="submit"
                loading={isSubmitting}
              >
                Absenden
              </AppButton>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
