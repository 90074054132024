import { Spinner, Button } from "react-bootstrap";

export default function AppButton({
  disabled,
  loading,
  children,
  variant,
  type,
  className,
  ...props
}) {
  return (
    <Button
      variant={variant || "primary"}
      type={type || "submit"}
      className={className || "d-flex m-auto"}
      disabled={disabled || loading || false}
      {...props}
    >
      {loading ? (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        </>
      ) : (
        children
      )}
    </Button>
  );
}
