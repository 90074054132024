import { useRef, useState, useEffect } from "react";
import { getCounter, setCounter } from "apis";

export default function Impressum() {
  const [updated, setUpdated] = useState(false);
  const [counter, setCounterLocal] = useState("000000");
  useEffect(() => {
    if (!updated) {
      setUpdated(true);
      setCounter("impressum").then(() => {
        getCounter().then((data) => {
          setCounterLocal(data.data.cnt);
        });
      });
    }
  }, [updated]);
  return (
    <p>
      <h6>Impressum</h6>
      LESSzwei GmbH
      <br />
      Naundoerfchen 32
      <br />
      04109 Leipzig
      <br />
      <br />
      Fon: +49 (0)341 - 247 31313
      <br />
      E-Mail: info@lesszwei.com
      <br />
      WEB: www.lesszwei.com
      <br />
      <br />
      Prokurist:
      <br />
      Jens Seidel
      <br />
      <br />
      HRB: 39973
      <br />
      Amtsgericht: Leipzig <br /> <br />
      Umsatzsteuer-Identifikationsnummer gemäß 27a Umsatzsteuergesetz:
      DE307098211
    </p>
  );
}
