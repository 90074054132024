import React from "react";
import { Carousel, Col, Container, Image, Row } from "react-bootstrap";

export default function Gallery() {
  return (
    <Container className="d-block m-auto py-5" style={{ width: "100%" }}>
      <Row>
        <Col>
          <Carousel variant="dark">
            {[
              { url: require("../../assets/home-slider/1.jpg") },
              { url: require("../../assets/home-slider/2.jpg") },
              { url: require("../../assets/home-slider/3.jpg") },
              { url: require("../../assets/home-slider/banner4.jpg") },
              { url: require("../../assets/home-slider/banner5.jpg") },
              { url: require("../../assets/home-slider/banner6.jpg") },
            ].map((el, index) => (
              <Carousel.Item key={`carousel-${index}`}>
                <Image
                  className="d-block w-100"
                  src={el.url}
                  alt={`Galleriebild: ${index}`}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
}
