import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./screens/Home";
import NotFound from "./screens/NotFound";
import Download from "screens/Download";
import Contact from "screens/Contact";
import FAQs from "screens/FAQs";
import AGB from "screens/AGB";
import Impressum from "screens/Impressum";
import PrivacyPolicy from "screens/PrivacyPolicy";

import About from "screens/About";

export default function CustomRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/download" element={<Download />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/agb" element={<AGB />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}
