import { useRef, useState, useEffect } from "react";
import { getCounter, setCounter } from "apis";

export default function AGB() {
  const [updated, setUpdated] = useState(false);
  const [counter, setCounterLocal] = useState("000000");
  useEffect(() => {
    if (!updated) {
      setUpdated(true);
      setCounter("agb").then(() => {
        getCounter().then((data) => {
          setCounterLocal(data.data.cnt);
        });
      });
    }
  }, [updated]);

  return (
    <p>
      <h4>I. Allgemeines</h4>
      1. Allen Lieferungen und Leistungen liegen diese Bedingungen sowie etwaige
      gesonderte vertragliche Vereinbarungen zugrunde. Abweichende
      Einkaufsbedingungen des Bestellers werden auch durch Auftragsannahme nicht
      Vertragsinhalt. Ein Vertrag kommt - mangels besonderer Vereinbarung - mit
      der schriftlichen Auftragsbestätigung des Lieferers zustande.
      <br />
      <br />
      2. Der Lieferer behält sich an Mustern, Kostenvoranschlägen, Zeichnungen u
      .ä. Informationen körperlicher und unkörperlicher Art - auch in
      elektronischer Form - Eigentums- und Urheberrechte vor; sie dürfen Dritten
      nicht zugänglich gemacht werden. Der Lieferer verpflichtet sich, vom
      Besteller als vertraulich bezeichnete Informationen und Unterlagen nur mit
      dessen Zustimmung Dritten zugänglich zu machen.
      <br />
      <br />
      <h4>II. Preis und Zahlung</h4>
      1. Die Preise gelten mangels besonderer Vereinbarung ab Werk
      einschließlich Verladung im Werk, jedoch ausschließlich Entladung. Zu den
      Preisen kommt die Umsatzsteuer in der jeweiligen gesetzlichen Höhe hinzu.
      <br />
      <br />
      2. Mangels besonderer Vereinbarung ist die Zahlung ohne jeden Abzug á
      Konto des Lieferers zu leisten binnen 30 Tagen ab Rechnungsdatum. 3. Das
      Recht, Zahlungen zurückzuhalten oder mit Gegenansprüchen aufzurechnen,
      steht dem Besteller nur insoweit zu, als seine Gegenansprüche unbestritten
      oder rechtskräftig festgestellt sind.
      <br />
      <br />
      <h4>III. Lieferzeit, Lieferverzögerung</h4>
      1. Die Lieferzeit ergibt sich aus den Vereinbarungen der Vertragsparteien.
      Ihre Einhaltung durch den Lieferer setzt voraus, dass alle kaufmännischen
      und technischen Fragen zwischen den Vertragsparteien geklärt sind und der
      Besteller alle ihm obliegenden Verpflichtungen, wie z.B. Beibringung der
      erforderlichen behördlichen Bescheinigungen oder Genehmigungen oder die
      Leistung einer Anzahlung erfüllt hat. Ist dies nicht der Fall, so
      verlängert sich die Lieferzeit angemessen. Dies gilt nicht, soweit der
      Lieferer die Verzögerung zu vertreten hat.
      <br />
      <br />
      2. Die Einhaltung der Lieferfrist steht unter dem Vorbehalt richtiger und
      rechtzeitiger Selbstbelieferung. Sich abzeichnende Verzögerungen teilt der
      Lieferer sobald als möglich mit.
      <br />
      <br />
      3. Die Lieferfrist ist eingehalten, wenn der Liefergegenstand bis zu ihrem
      Ablauf das Werk des Lieferers verlassen hat oder die Versandbereitschaft
      gemeldet ist. Soweit eine Abnahme zu erfolgen hat, ist – außer bei
      berechtigter Abnahmeverweigerung – der Abnahmetermin maßgebend, hilfsweise
      die Meldung der Abnahmebereitschaft.
      <br />
      <br />
      4. Werden der Versand bzw. die Abnahme des Liefergegenstandes aus Gründen
      verzögert, die der Besteller zu vertreten hat, so werden ihm, beginnend
      einen Monat nach Meldung der Versand- bzw. der Abnahmebereitschaft, die
      durch die Verzögerung entstandenen Kosten berechnet. 5. Ist die
      Nichteinhaltung der Lieferzeit auf höhere Gewalt, auf Arbeitskämpfe oder
      sonstige Ereignisse, die Außerhalb des Einflussbereiches des Lieferers
      liegen, zurückzuführen, so verlängert sich die Lieferzeit angemessen. Der
      Lieferer wird dem Besteller den Beginn und das Ende derartiger Umstände
      baldmöglichst mitteilen.
      <br />
      <br />
      6. Der Besteller kann ohne Fristsetzung vom Vertrag zurücktreten, wenn dem
      Lieferer die gesamte Leistung vor Gefahrübergang endgültig unmöglich wird.
      Der Besteller kann darüber hinaus vom Vertrag zurücktreten, wenn bei einer
      Bestellung die Ausführung eines Teils der Lieferung unmöglich wird und er
      ein berechtigtes Interesse an der Ablehnung der Teillieferung hat. Ist
      dies nicht der Fall, so hat der Besteller den auf die Teillieferung
      entfallenden Vertragspreis zu zahlen. Dasselbe gilt bei Unvermögen des
      Lieferers. Im Übrigen gilt Abschnitt VII. 2. Tritt die Unmöglichkeit oder
      das Unvermögen während des Annahmeverzuges ein oder ist der Besteller für
      diese Umstände allein oder weit überwiegend verantwortlich, bleibt er zur
      Gegenleistung verpflichtet.
      <br />
      <br />
      7. Kommt der Lieferer in Verzug und erwächst dem Besteller hieraus ein
      Schaden, so ist er berechtigt, eine pauschale Verzugsentschädigung zu
      verlangen. Sie beträgt für jede volle Woche der Verspätung 0,5 %, im
      Ganzen aber höchstens 5 % vom Wert desjenigen Teils der Gesamtlieferung,
      der infolge der Verspätung nicht rechtzeitig oder nicht vertragsgemäß
      genutzt werden kann. Setzt der Besteller dem Lieferer - unter
      Berücksichtigung der gesetzlichen Ausnahmefälle - nach Fälligkeit per
      Einschreiben/Rückschein eine angemessene Frist zur Leistung und wird die
      Frist nicht eingehalten, ist der Besteller im Rahmen der gesetzlichen
      Vorschriften zum Rücktritt berechtigt. Weitere Ansprüche aus Lieferverzug
      bestimmen sich ausschließlich nach Abschrift VII. 2 dieser Bedingungen.
      <br />
      <br />
      <h4>IV. Gefahrübergang, Abnahme</h4>
      1. Die Gefahr geht auf den Besteller über, wenn der Liefergegenstand das
      Werk verlassen hat, und zwar auch dann, wenn Teillieferungen erfolgen oder
      der Lieferer noch andere Leistungen, z.B. die Versandkosten oder
      Anlieferung und Aufstellung übernommen hat. Soweit eine Abnahme zu
      erfolgen hat, ist diese für den Gefahrübergang maßgebend. Sie muss
      unverzüglich zum Abnahmetermin, hilfsweise nach der Meldung des Lieferers
      über die Abnahmebereitschaft, durchgeführt werden. Der Besteller darf die
      Abnahme bei Vorliegen eines nicht wesentlichen Mangels nicht verweigern.
      <br />
      <br />
      2. Verzögert sich oder unterbleibt der Versand bzw. die Abnahme infolge
      von Umständen, die dem Lieferer nicht zuzurechnen sind, geht die Gefahr
      vom Tage der Meldung der Versand- bzw. Abnahmebereitschaft auf den
      Besteller über. Der Lieferer verpflichtet sich, auf Kosten des Bestellers
      die Versicherungen abzuschließen, die dieser verlangt. 3. Teillieferungen
      sind zulässig, soweit für den Besteller zumutbar.
      <br />
      <br />
      <h4>V. Eigentumsvorbehalt</h4>
      1. Bis zur Erfüllung aller Forderungen (einschließlich sämtlicher
      Saldoforderungen aus Kontokorrent), die dem Lieferer aus jedem Rechtsgrund
      gegen den Besteller jetzt oder künftig zustehen, werden dem Lieferer die
      folgenden Sicherheiten gewährt, die er auf Verlangen nach seiner Wahl
      freigeben wird, soweit ihr Wert die Forderungen nachhaltig um mehr als 20
      % übersteigt.
      <br />
      <br />
      2. Die Ware bleibt Eigentum des Lieferers. Verarbeitung oder Umbildung
      erfolgen stets für den Lieferer als Hersteller, jedoch ohne Verpflichtung
      für ihn. Erlischt das (Mit-)Eigentum des Lieferers durch Verbindung, so
      wird bereits jetzt vereinbart, dass das (Mit-)Eigentum des Bestellers an
      der einheitlichen Sache wertanteilmäßig (Rechnungswert) auf den Lieferer
      übergeht. Der Besteller verwahrt das (Mit-)Eigentum des Lieferers
      unentgeltlich. Ware, an der dem Lieferer (Mit-)Eigentum zusteht, wird im
      Folgenden als Vorbehaltsware bezeichnet.
      <br />
      <br />
      3. Der Besteller ist berechtigt, die Vorbehaltsware im ordnungsgemäßen
      Geschäftsverkehr zu verarbeiten und zu veräußern, solange er nicht in
      Verzug ist. Verpfändungen oder Sicherungsübereignungen sind unzulässig.
      Die aus dem Weiterverkauf oder einem sonstigen Rechtsgrund (Versicherung,
      unerlaubte Handlung) bezüglich der Vorbehaltsware entstehenden Forderungen
      (einschließlich sämtlicher Saldoforderungen aus Kontokorrent) tritt der
      Besteller bereits jetzt sicherungshalber in vollem Umfang an den Lieferer
      ab. Der Lieferer ermächtigt ihn widerruflich, die an den Lieferer
      abgetretenen Forderungen für dessen Rechnung im eigenen Namen einzuziehen.
      Diese Einziehungsermächtigung kann nur widerrufen werden, wenn der
      Besteller seinen Zahlungsverpflichtungen nicht ordnungsgemäß nachkommt.
      <br />
      <br />
      4. Bei Zugriffen Dritter auf die Vorbehaltsware, insbesondere Pfändungen,
      wird der Besteller auf das Eigentum des Lieferers hinweisen und diesen
      unverzüglich benachrichtigen, damit der Lieferer seine Eigentumsrechte
      durchsetzten kann. Soweit der Dritte nicht in der Lage ist, dem Lieferer
      die in diesem Zusammenhang entstehenden gerichtlichen oder
      außergerichtlichen Kosten zu erstatten, haftet hierfür der Besteller.
      <br />
      <br />
      5. Bei vertragswidrigem Verhalten des Bestellers – insbesondere
      Zahlungsverzug – ist der Lieferer berechtigt, die Vorbehaltsware
      zurückzunehmen oder gegebenenfalls Abtretung der Herausgabeansprüche des
      Bestellers gegen Dritte zu verlangen. In der Zurücknahme sowie in der
      Pfändung der Vorbehaltsware durch den Lieferer liegt kein Rücktritt vom
      Vertrage.
      <br />
      <br />
      6. Der Antrag auf Eröffnung des Insolvenzverfahrens über das Vermögen des
      Bestellers berechtigt den Lieferer, vom Vertrag zurückzutreten und die
      sofortige Rückgabe des Liefergegenstandes zu verlangen.
      <br />
      <br />
      <h4>VI. Mängelansprüche</h4>
      Für Sach- und Rechtsmängel der Lieferung leistet der Lieferer unter
      Ausschluss weiterer Ansprüche –vorbehaltlich Abschnitt VII – Gewähr wie
      folgt:
      <br />
      <br />
      Sachmängel
      <br />
      <br />
      1. Alle diejenigen Teile sind unentgeltlich nach Wahl des Lieferers
      nachzubessern oder mangelfrei zu ersetzen, die sich infolge eines vor dem
      Gefahrübergang liegenden Umstandes als mangelhaft herausstellen. Die
      Festlegung solcher Mängel ist dem Lieferer innerhalb von 5 Werktagen
      schriftlich anzuzeigen. Unterlässt der Besteller diese Anzeige, gilt die
      Ware als genehmigt. Ersetzte Teile werden Eigentum des Lieferers.
      <br />
      <br />
      2. Zur Vornahme aller dem Lieferer notwendig erscheinenden Nachbesserungen
      und Ersatzlieferungen hat der Besteller nach Verständigung mit dem
      Lieferer, dem er Gelegenheit zur Besichtigung des mangelhaften Teiles
      einzuräumen hat, die erforderliche Zeit und Gelegenheit zu geben;
      anderenfalls ist der Lieferer von der Haftung für die daraus entstehenden
      Folgen befreit. Nur in dringenden Fällen der Gefährdung der
      Betriebssicherheit bzw. zur Abwehr unverhältnismäßig großer Schäden, wobei
      der Lieferer sofort zu verständigen ist, hat der Besteller das Recht, den
      Mangel selbst oder durch Dritte beseitigen zu lassen und vom Lieferer
      Ersatz der erforderlichen Aufwendungen in Form von Warengutschrift zu
      verlangen.
      <br />
      <br />
      3. Von den durch die Nachbesserung bzw. Ersatzlieferung entstehenden
      unmittelbaren Kosten trägt der Lieferer – soweit sich die Beanstandung als
      berechtigt herausstellt – die Kosten des Ersatzstückes einschließlich des
      Versandes. Er trägt außerdem die Kosten des Aus- und Einbaus sowie die
      Kosten der etwa erforderlichen Gestellung der notwendigen Monteure und
      Hilfskräfte einschließlich Fahrtkosten, soweit hierdurch keine
      unverhältnismäßige Belastung des Lieferers eintritt. Der Aufwendungsersatz
      gemäß vorstehendem Satz erfolgt nur in Form von Warengutschrift.
      <br />
      <br />
      4. Der Besteller hat im Rahmen der gesetzlichen Vorschriften ein Recht zum
      Rücktritt vom Vertrag, wenn der Lieferer – unter Berücksichtigung der
      gesetzlichen Ausnahmefälle – eine ihm per Einschreiben/Rückschein gesetzte
      angemessene Frist für die Nachbesserung oder Ersatzlieferung wegen eines
      Sachmangels fruchtlos verstreichen lässt und er auch innerhalb einer
      weiteren ihm per Einschreiben/Rückschein gesetzten angemessenen Nachfrist
      den Mangel nicht beseitigt hat. Liegt nur ein unerheblicher Mangel vor,
      steht dem Besteller lediglich ein Recht zur Minderung des Vertragspreises
      zu. Das Recht auf Minderung des Vertragspreises bleibt ansonsten
      ausgeschlossen. Weitere Ansprüche bestimmen sich nach Abschnitt VII. 2
      dieser Bedingungen.
      <br />
      <br />
      5. Keine Gewähr wird insbesondere in folgenden Fällen übernommen:
      <br />
      <br />
      Ungeeignete oder unsachgemäße Verwendung, fehlerhafte Montage bzw.
      Inbetriebsetzung durch den Besteller oder Dritte, natürliche Abnutzung,
      fehlerhafte oder nachlässige Behandlung, nicht ordnungsgemäße Wartung,
      ungeeignete Betriebsmittel, mangelhafte Bauarbeiten, ungeeigneter
      Baugrund, chemische, elektrochemische oder elektrische Einflüssen – sofern
      sie nicht vom Lieferer zu verantworten sind.
      <br />
      <br />
      6. Bessert der Besteller oder ein Dritter unsachgemäß nach, besteht keine
      Haftung des Lieferers für die daraus entstehenden Folgen. Gleiches gilt
      für ohne vorherige Zustimmung des Lieferers vorgenommene Änderungen des
      Liefergegenstandes.
      <br />
      <br />
      7. Ein Aufwendungsersatz gemäß Abschnitt VI. 2 und 3 ist für solche
      Aufwendungen ausgeschlossen, die bei hinreichender Vorsorge des Bestellers
      für die Nachlieferung nicht angefallen wären. Im Übrigen wird der
      Aufwendungsersatz auf solche Aufwendungen beschränkt, die typischerweise
      vorhersehbar sind bzw. waren. <br />
      <br />
      Rechtsmängel
      <br />
      <br />
      8. Führt die Benutzung des Liefergegenstandes zur Verletzung von
      gewerblichen Schutzrechten oder Urheberrechten im Inland, wird der
      Lieferer auf seine Kosten dem Besteller grundsätzlich das Recht zum
      weiteren Gebrauch verschaffen oder den Liefergegenstand in für den
      Besteller zumutbarer Weise derart modifiziert, dass die
      Schutzrechtsverletzung nicht mehr besteht. Ist dies zu wirtschaftlich
      angemessenen Bedingungen oder in angemessener Frist nicht möglich, ist der
      Besteller zum Rücktritt vom Vertrag berechtigt. Unter den genannten
      Voraussetzungen steht auch dem Lieferer ein Recht zum Rücktritt vom
      Vertrag zu. Darüber hinaus wird der Lieferer den Besteller von
      unbestrittenen oder rechtskräftig festgestellten Ansprüchen der
      betreffenden Schutzrechtsinhaber freistellen.
      <br />
      <br />
      9. Die in Abschnitt VI. 8 genannten Verpflichtungen des Lieferers sind
      vorbehaltlich Abschnitt VII. 2 für den Fall der Schutz- oder
      Urheberrechtsverletzung abschließend. Sie bestehen nur, wenn
      <br />
      <br />• der Besteller den Lieferer unverzüglich von geltend gemachten
      Schutz- oder Urheberrechtsverletzungen unterrichtet, • der Besteller den
      Lieferer in angemessenem Umfang bei der Abwehr der geltend gemachten
      Ansprüche unterstützt bzw. dem Lieferer die Durchführung der
      Modifizierungsmaßnahmen gemäß Abschnitt VI. 8 ermöglicht, • dem Lieferer
      alle Abwehrmaßnahmen einschließlich außergerichtlicher Regelungen
      vorbehalten bleiben, • der Rechtsmangel nicht auf einer Anweisung des
      Bestellers beruht und • die Rechtsverletzung nicht dadurch verursacht
      wurde, dass der Besteller den Liefergegenstand eigenmächtig geändert oder
      in einer nicht vertragsgemäßen Weise verwendet hat.I. Allgemeines
      <h4>VII. Haftung</h4>
      1. Wenn der Liefergegenstand durch Verschulden des Lieferers infolge
      unterlassener oder fehlerhafter Ausführung von vor oder nach
      Vertragsschluss erfolgten Vorschlägen und Beratungen oder durch die
      Verletzung anderer vertraglicher Nebenverpflichtungen – insbesondere
      Anleitung für Bedienung und Wartung des Liefergegenstandes – vom Besteller
      nicht vertragsgemäß verwendet werden kann, so gelten unter Ausschluss
      weiterer Ansprüche des Bestellers die Regelungen der Abschnitte VI und
      VII. 2 entsprechend.
      <br />
      <br />
      2. Für Schäden, die nicht am Liefergegenstand selbst entstanden sind,
      haftet der Lieferer – aus welchen Rechtsgründen auch immer – nur
      <br />
      <br />
      a. bei Vorsatz, b. bei grober Fahrlässigkeit des Inhabers / der Organe
      oder leitender Angestellter, c. bei schuldhafter Verletzung von Leben,
      Körper, Gesundheit, d. bei Mängeln, die er arglistig verschwiegen oder
      deren Abwesenheit er garantiert hat, e. bei Mängeln des
      Liefergegenstandes, soweit nach Produkthaftungsgesetz für Personen- oder
      Sachschäden an privat genutzten Gegenständen gehaftet wird. Bei
      schuldhafter Verletzung wesentlicher Vertragspflichten haftet der Lieferer
      auch bei grober Fahrlässigkeit nicht leitender Angestellter und bei
      leichter Fahrlässigkeit, in letzterem Fall begrenzt auf den
      vertragstypischen, vernünftigerweise vorhersehbaren Schaden. Weitere
      Ansprüche sind ausgeschlossen.
      <br />
      <br />
      <h4>VIII. Verjährung</h4>
      Alle Ansprüche des Bestellers – aus welchen Rechtsgründen auch immer –
      verjähren in 12 Monaten. Für Schadensersatzansprüche nach Abschnitt VII. 2
      a – e gelten die gesetzlichen Fristen. Sie gelten auch für Mängel eines
      Bauwerkes oder für Liefergegenstände, die entsprechend ihrer üblichen
      Verwendungsweise für ein Bauwerk verwendet wurden und dessen
      Mangelhaftigkeit verursacht haben.
      <br />
      <br />
      <h4>IX. Softwarenutzung</h4>
      Soweit im Lieferumfang Software enthalten ist, wird dem Besteller ein
      nicht ausschließliches Recht eingeräumt, die gelieferte Software
      einschließlich ihrer Dokumentationen zu nutzen. Sie wird zur Verwendung
      auf dem dafür bestimmten Liefergegenstand überlassen. Eine Nutzung der
      Software auf mehr als einem System ist untersagt. Der Besteller darf die
      Software nur im gesetzlich zulässigen Umfang (§§ 69 a ff. UrhG)
      vervielfältigen, überarbeiten, übersetzen oder von dem Objektcode in den
      Quellcode umwandeln. Der Besteller verpflichtet sich, Herstellerangaben –
      insbesondere Copyright-Vermerke – nicht zu entfernen oder ohne vorherige
      ausdrückliche Zustimmung des Lieferers zu verändern. Alle sonstigen Rechte
      an der Software und den Dokumentationen einschließlich der Kopien bleiben
      beim Lieferer bzw. beim Softwarelieferanten. Die Vergabe von Unterlizenzen
      ist nicht zulässig.
      <br />
      <br />
      <h4>X. Anwendbares Recht, Gerichtsstand</h4>
      1. Für alle Rechtsbeziehungen zwischen dem Lieferer und dem Besteller gilt
      ausschließlich das für die Rechtsbeziehungen inländischer Parteien
      untereinander maßgebliche Recht der Bundesrepublik Deutschland unter
      Ausschluss internationaler Kollisionsrechtes.
      <br />
      <br />
      2. Gerichtsstand ist das für den Sitz des Lieferers zuständige Gericht.
      Der Lieferer ist jedoch berechtigt, am Hauptsitz des Bestellers Klage zu
      erheben.
      <br />
      <br />
      Stand Juni 2022
    </p>
  );
}
