import React from "react";

export default function PageHeader({ title, subTitle }) {
  return (
    <div className="py-5 page-header">
      <h4 className="text-center">{title}</h4>
      <p className="text-center page-sub-heading mt-3">{subTitle}</p>
    </div>
  );
}
