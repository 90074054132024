import ACTION_TYPES from "constants/actionsTypes";
import jwt from "jwt-decode";

const initalState = {
    accessToken: undefined,
    refreshToken: undefined,
    session: undefined,
    isLoggedIn: false,
};

export default function loginReducer(state = initalState, action) {
    const newState = Object.assign({}, state);
    console.log("New State: ", newState);
    switch (action.type) {
        case ACTION_TYPES.LOGIN.LOGIN:
            console.log("Action: ", action);
            const {
                payload: { token, refreshToken, session, user },
            } = action;
            console.log("user here: ", user);
            const { first_name, last_name, email, mobile } = user;
            return {
                ...newState,
                token,
                refreshToken,
                session,
                isLoggedIn: true,
                firstName: first_name,
                lastName: last_name,
                email,
                mobile,
            };
        default:
            return state;
    }
}