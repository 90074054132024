import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import DESKTOP from "../../assets/icons/desktop.png";
import TOGGLES from "../../assets/icons/toggles.png";
import TROPHY from "../../assets/icons/trophy.png";
import Divider from "components/Divider";
import "./styles.scss";
import { isMobile, isTablet } from "react-device-detect";
var Scroll = require("react-scroll");

export default function HowItWorks(props) {
  var Element = Scroll.Element;

  return (
    <Element id="how-it-works-container" name="how-it-works">
      <Container>
        <Row>
          <Col xs="8" className="m-auto" style={{ textAlign: "center" }}>
            {isMobile ? <h4>Was ist PV-Port?</h4> : <h1>Was ist PV-Port?</h1>}

            <p className="sub-heading mb-0">
              Das Produkt PV Port & Store (ein DIY "System") ist eine
              standardisierte Plug-n-Play, tragbare und einfach zu
              installierende bis zu 3kWp Solar-PV-Anlage mit elektrischem
              Speicher für Anwendungen im privaten Wohnen und gewerblichen
              Anwendungen.
            </p>
            <Divider />
          </Col>
        </Row>
        <Row xs="1" sm="3">
          {[
            {
              icon: DESKTOP,
              text: "",
              title: "Bestellen",
            },
            {
              icon: TOGGLES,
              text: "",
              title: "Zusammenbauen",
            },
            {
              icon: TROPHY,
              text: "",
              title: "Anschliessen",
            },
          ].map((el, index) => (
            <Col key={`${index}-${el.title}`}>
              <Image src={el.icon} className="d-block m-auto" />
              <h3>{el.title}</h3>
              <p className="text-center">{el.text}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </Element>
  );
}
