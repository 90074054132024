import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import { HashLink } from "react-router-hash-link";
import RegisterModal from "components/RegisterModal";

import { connect } from "react-redux";
import "./styles.scss";
var Scroll = require("react-scroll");
var scroller = Scroll.scroller;
var Link = Scroll.Link;

function Header({ isLoggedIn, firstName, lastName }) {
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  console.log("Logged In here: ", isLoggedIn);

  return (
    <>
      <Navbar bg="light" variant="light" sticky="top" expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <Image src={require("assets/logo.png")} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">PV PORT & STORE</Nav.Link>

              <Nav.Link href="/download">DOWNLOADS</Nav.Link>
              <Nav.Link href="/about">Mehr über PV-Port</Nav.Link>
              <Nav.Link href="/contact">Kontakt</Nav.Link>
            </Nav>
            {!isLoggedIn ? (
              <div className="d-flex align-items-center justify-content-evenly header-button-container">
                <Button variant="header" onClick={() => setShowRegister(true)}>
                  REGISTRIERUNG
                </Button>
              </div>
            ) : (
              <Navbar.Text>
                Signed in as:{" "}
                <a href="/profile">
                  {firstName} {lastName}
                </a>
              </Navbar.Text>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {!isLoggedIn && (
        <>
          <RegisterModal
            show={showRegister}
            onClose={() => setShowRegister(false)}
            product2={"Alle"}
          />
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.loginReducer.isLoggedIn,
  firstName: state.loginReducer.firstName,
  lastName: state.loginReducer.lastName,
});

export default connect(mapStateToProps, null)(Header);
