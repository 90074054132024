import React from "react";
import "./styles.scss";

export default function Divider({ variant }) {
  const variants = {
    1: "variant-1",
    2: "variant-2",
  };
  return <hr className={`d-block mx-auto ${variants[variant || 1]}`} />;
}
