import React from "react";

export default function NotFound(props) {
  return (
    <div
      className="w-100 h-100 d-flex justify-content-center align-items-center"
      style={{ height: "90%" }}
    >
      Not Found
    </div>
  );
}
